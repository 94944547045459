import Swiper from 'swiper';
export function recruit(controller , scene){
    const recruitswipe =  document.getElementById('recruit-swipe');
    if(recruitswipe){
        const plam = {
            autoplay: {
            	delay: 4000,
            	disableOnInteraction: false,
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 600,
            spaceBetween: 30,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#recruit-swipe' , plam);
    }    
}