import Swiper from 'swiper';
export function index(controller , scene){
    const fv = document.getElementById('firstview');
    let resizeTimer;
    if(fv){
        function onResize(){
            fv.style.height = window.innerHeight + 'px';
        }
        window.addEventListener('resize', function (event) {
            if (!resizeTimer) {
                clearTimeout(resizeTimer);
            }
            resizeTimer = setTimeout(function () {
                onResize();
            }, 100);
        });
        onResize();
    }
    
    const catchs = document.getElementById('fvcatch');
    if(catchs){
        setTimeout(function(){
            catchs.classList.add('active');
        },800);
    }

    const bnrswipe =  document.getElementById('bnr-swipe');
    if(bnrswipe){
        const bnrplam = {
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 600,
            spaceBetween: 50,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#bnr-swipe' , bnrplam);
    }
    
    const indexswipe =  document.getElementById('index-swipe');
    if(indexswipe){
        const plam = {
            autoplay: {
            	delay: 2000,
            	disableOnInteraction: false,
            },
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 400,
            spaceBetween: 50,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#index-swipe' , plam);
    }    
    
}