import {scrolEvents} from './default';
import {index} from './index';
import {loading} from './loading';
import {math} from './math';
import {recruit} from './recruit';
import {vues} from './vues';
import {advertise} from './advertise';

document.addEventListener('DOMContentLoaded', function() {
    
    var ua = window.navigator.userAgent.toLowerCase();
    if(ua.indexOf("windows nt") !== -1) {
        document.body.classList.add('windows');
    }
    
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    vues();
    advertise();
	scrolEvents(controller , scene);
    math(controller , scene);
    recruit(controller , scene);
    
    // LOADING 処理
    const keyName = 'visited';
    const keyValue = true;
    const app = document.getElementById('app');

    if (!sessionStorage.getItem(keyName)) {
        sessionStorage.setItem(keyName, keyValue);
        loading();
        setTimeout(function(){
            app.classList.add('active');
        },100);
    } else {
        const intro = document.getElementById('intro');
        if(intro){
            intro.remove();
        }
        app.classList.add('active');
        index();
    }

    
});



