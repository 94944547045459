export function advertise(){
    const c = new Vue({
        el: '#modalarea',
        data: {
            modal: false,
            images: null,
        },
        methods: {
            modalopen: function(el){
                this.modal = true;
                this.images = el;
            }
        },
    });
}